import React, { useRef } from "react"
import { graphql } from "gatsby"
import { Spring } from "react-spring/renderprops"
import { css } from "@emotion/core"
import Img from "gatsby-image"

import { fonts, mediaQueries, container, weights } from "../styles"
import Layout from "../components/layout"
import FullWidthSection from "../components/FullWidthSection"
import { useHasBeenVisible } from "../hooks/useVisibility"

const Capability = ({ imageSrc, imageAlt, content, index, id }) => {
  const nodeRef = useRef()
  const isVisible = useHasBeenVisible(nodeRef)

  return (
    <FullWidthSection
      ref={nodeRef}
      height="0"
      padding="0"
      textAlign="left"
      css={css`
        &:first-of-type {
          margin-top: 20px;

          ${mediaQueries.phoneLarge} {
            margin-top: 175px;
          }
        }
      `}
    >
      <div id={id} css={container.medium}>
        <div
          css={css`
            margin-bottom: 90px;

            ${mediaQueries.phoneLarge} {
              display: flex;
              justify-content: space-between;
              flex-direction: ${index % 2 ? "row-reverse" : "row"};
              align-items: center;
              margin-bottom: 170px;
            }

            h2 {
              font-size: 33px;
              font-weight: ${weights.bold};
            }

            p {
              font-weight: ${weights.light};
            }

            ul {
              margin: 0;
              padding: 0 0 0 0.3rem;
              font-size: 18px;

              li {
                font-family: ${fonts.sans};
                font-weight: ${weights.bold};
                font-variant-caps: all-small-caps;
                letter-spacing: 1px;
                list-style: none;
              }

              li:before {
                content: "- ";
              }
            }
          `}
        >
          <Spring
            delay={0}
            to={{
              transform: isVisible ? "translateY(0)" : "translateY(200px)",
              opacity: isVisible ? "1" : "0",
            }}
          >
            {({ transform, opacity }) => (
              <Img
                fluid={imageSrc}
                alt={imageAlt}
                style={{ transform, opacity }}
                css={css`
                  width: 100%;
                  margin-bottom: 20px;

                  > div {
                    padding-bottom: 100% !important;
                  }

                  ${mediaQueries.phoneLarge} {
                    flex: 0 0 39%;
                    width: 39%;
                    margin-bottom: 0;

                    > div {
                      padding-bottom: ${index % 2 ? "76% !important" : "100%"};
                      padding-bottom: ${index % 4 === 2
                        ? "131% !important"
                        : "100%"};
                    }
                  }
                `}
              />
            )}
          </Spring>

          <div
            css={css`
              position: relative;

              ${mediaQueries.phoneLarge} {
                flex: 0 0 50%;
                width: 50%;
              }
            `}
          >
            {content}
          </div>
        </div>
      </div>
    </FullWidthSection>
  )
}

const CapabilitiesPage = ({ data }) => {
  return (
    <Layout
      headerData={{
        title: "製品 / サービス",
        mobileMinHeight: "93vh",
        height: "400px",
      }}
    >
      <Capability
        id="mobile"
        imageSrc={data.mobileImage.childImageSharp.fluid}
        imageAlt="Laptop on desk with drink"
        content={
          <>
            <h2>モバイルソリューション事業</h2>
            <p>
              シードシステムは、モバイル事業に2001年から取り組んでいます。公式サイトの開発は、約600件以上と業界一の実績があります。多くの実践経験の中で、柔軟性の高い技術を持った数多くのSEが育ちました。お客様の「やりたい」に、幅広い提案力からポイントを押さえた最適な提案を行うコンサルティング能力と、しっかりと作り込む職人気質、加えて運用面まで細かいフォローでお応えします。最初の打合せから勘どころのいい対応には、「まかせて安心」との評価をいただいています。
            </p>
            <p>
              SNSやスマートフォンへの対応もいち早く着手し、新しいサービスであっても、変わらぬ安定した質でご提供しています。「クラウド」「ソーシャルアプリ」「スマートフォン」「グローバル展開」など、先進的なサービスへ積極的にアプローチされるお客様にも、本当に最適な選択は何かを念頭に、親身なアドバイスで対応いたします。シードシステムの仕事は、「受け取った仕様書通りに納めて正解」ではありません。お客様の利益を作り出すサイトになって、はじめて正解だとシードシステムは考えます。
            </p>
            <p>
              安定した運営が可能なインフラ、常に改善を念頭に取り組む保守や運営など、モバイルビジネス全般のサービスをワンストップで、責任を持ってご提供します。
            </p>
            <p>
              これまで、モバイルでの情報発信をされてこなかった方も、公式サイトやさまざまなモバイル活用をお考えなら、まずシードシステムにご相談いただければ、ゼロからの安心したサポートでご対応いたします。
            </p>
          </>
        }
        index={0}
      />
      <Capability
        id="package"
        imageSrc={data.packageImage.childImageSharp.fluid}
        imageAlt="Two office workers looking at a chart on a laptop"
        content={
          <>
            <h2>パッケージソリューション事業</h2>
            <p>
              シードシステムのパッケージ製品は、自社の課題、お客様からの受託開発、マーケットの需要などを精査して生まれました。現場の課題解決で培ったノウハウや汎用性の高いソフトをひとつにまとめることで、より広い仕事の現場で役立つようにしました。「安くて多機能」ではなく、「便利さがひとまとめ」になっているので、ＩＴリテラシーに依存することなく、現場の誰にとっても使い勝手がよいと好評です。
            </p>
            <p>
              導入後も、保守サービスを通じて、使用実感、改善要望、拡張希望などのお客様の声を中心に取り込み、新しい課題に対応したバージョンアップに加え、関連ソフトやサービスも最新のものをご提供しています。シードシステムのパッケージ製品を継続使用することで、常に最新の業務ノウハウも吸収できるのです。外国語にも対応した仕様の製品は、お客様が、事業をグローバル展開する時にも役立ちます。導入後も、専用ヘルプデスク・定期無料セミナー・ご訪問操作指導・ユーザーサポートサイトなど、充実の体制でサポートします。
            </p>
          </>
        }
        index={1}
      />
      <Capability
        id="outsourcing"
        imageSrc={data.outsourcingImage.childImageSharp.fluid}
        imageAlt="Man drawing logos in a notebook"
        content={
          <>
            <h2>エンジニア・アウトソーシング事業</h2>
            <p>
              当社は、単なる技術者の派遣会社ではなく、開発会社として、お客様のニーズに沿った人材サービスを提供させて頂いております。
            </p>
            <p>
              必要なときに適切な人材を、適切な期間、適切なポジションで、貴社のプロジェクト成功に貢献できる人材をご紹介致します。
            </p>
            <p>
              予めお客様の計画、ニーズをヒアリングさせて頂くことで、現場で必要とされる知識、スキルを身に着け、即戦力としての能力を高めてからご紹介することも可能であり、若い人材と経験者両方の要員提案を行うことが可能です。
            </p>
          </>
        }
        index={2}
      />
    </Layout>
  )
}

export const query = graphql`
  query CapabilitiesQuery {
    mobileImage: file(relativePath: { eq: "mobile-dev.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    packageImage: file(relativePath: { eq: "package.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    outsourcingImage: file(relativePath: { eq: "outsourcing.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default CapabilitiesPage
